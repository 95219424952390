import React from 'react';
import Layout from '../layouts/index';
import DishesImg from "../images/dishes.jpg"
import LightDishesImg from '../images/light-dishes.jpg'
import HandingSheetToImg from '../images/handing-sheet-to.jpg'
import BloodDrawingImg from '../images/blood-drawing.jpg'
import XrayImg from '../images/xray.jpg'
import UltrasonicReflectoscopeControllerImg from '../images/ultrasonic-reflectoscope-controller.jpg'
import RestroomsImg from '../images/restrooms.jpg'
import AnesthesiaTubeImg from '../images/anesthesia-tube.jpg'
import HandingEndoscopeImg from '../images/handing-endoscope.jpg'
import InterviewImg from '../images/interview.jpg'

const FlowContent = () => (
  <div className="container sub-content">
    <h1>健康診断の流れ</h1>
    <div>
      <div>
        <h2>まずはお電話でご予約</h2>
      </div>
      <div>
        <div className="row box">
          <div className="col-sm-4">
            <img
              className="img-with-text img-responsive center-block img-thumbnail"
              src={DishesImg}
            />
          </div>
          <div className="col-sm-8">
            <div>
              <h3>午前中に健診･ドックを受けられる方</h3>
            </div>
            <p>
              前日はできるだけ夕食を夜９時までに済ませるようにしてください。
              <br />
              （消化の悪いものはできるだけ避けてください。）
              <br />
              水やお茶はとっていただいてかまいません。
            </p>
          </div>
        </div>
        <div className="row box">
          <div className="col-sm-4">
            <img
              className="img-with-text img-responsive center-block img-thumbnail"
              src={LightDishesImg}
            />
          </div>
          <div className="col-sm-8">
            <div>
              <h3>午後に健診を受けられる方</h3>
            </div>
            <p>
              当日の朝はできるだけ消化のいいものを、少量のみ摂るようにしてください。（ウィダーインゼリーなどがベスト。）
              水やお茶はとっていただいてかまいませんが、検査の前は控えるようにしてください。
              <br />
              ジュースやコーヒーなどは避けてください。
            </p>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div>
        <h2>来院されたら</h2>
      </div>
      <div>
        <div>
          <div>
            <div className="row box">
              <div className="col-sm-4">
                <img
                  className="img-with-text img-responsive center-block img-thumbnail"
                  src={HandingSheetToImg}
                />
              </div>
              <div className="col-sm-8">
                <div>
                  <h3>Step01</h3>
                </div>
                <p>
                  まずは受付に健診･ドックで来られた旨と、お名前をお申し出ください。
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className="row box">
              <div className="col-sm-4">
                <img
                  className="img-with-text img-responsive center-block img-thumbnail"
                  src={BloodDrawingImg}
                />
              </div>
              <div className="col-sm-8">
                <div>
                  <h3>Step02</h3>
                </div>
                <p>
                  看護師の案内でお着替えいただき、身体測定、採血などを行います。
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className="row box">
              <div className="col-sm-4">
                <img
                  className="img-with-text img-responsive center-block img-thumbnail"
                  src={XrayImg}
                />
              </div>
              <div className="col-sm-8">
                <div>
                  <h3>Step03</h3>
                </div>
                <p>そのあと、診察、レントゲン撮影となります。</p>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className="row box">
              <div className="col-sm-4">
                <img
                  className="img-with-text img-responsive center-block img-thumbnail"
                  src={UltrasonicReflectoscopeControllerImg}
                />
              </div>
              <div className="col-sm-8">
                <div>
                  <h3>Step04</h3>
                </div>
                <p>
                  超音波検査（腹部、乳腺）がある方は、レントゲンの後、超音波検査となります。
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className="row box">
              <div className="col-sm-4">
                <img
                  className="img-with-text img-responsive center-block img-thumbnail"
                  src={RestroomsImg}
                />
              </div>
              <div className="col-sm-8">
                <div>
                  <h3>Step05</h3>
                </div>
                <p>
                  尿がたまっていないと膀胱や前立腺などの観察ができないため、尿検査は超音波検査の後になります。排尿はできるだけ超音波検査が終わるまでしないようにしてください。
                  <br />
                  また、胃カメラの検査がある方は、超音波検査の前後に鼻に粘膜を収縮させる薬剤をスプレーします。
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className="row box">
              <div className="col-sm-4">
                <img
                  className="img-with-text img-responsive center-block img-thumbnail"
                  src={AnesthesiaTubeImg}
                />
              </div>
              <div className="col-sm-8">
                <div>
                  <h3>Step06</h3>
                </div>
                <p>超音波検査、尿検査が終わったら、鼻の麻酔、のどの麻酔。</p>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className="row box">
              <div className="col-sm-4">
                <img
                  className="img-with-text img-responsive center-block img-thumbnail"
                  src={HandingEndoscopeImg}
                />
              </div>
              <div className="col-sm-8">
                <div>
                  <h3>Step07</h3>
                </div>
                <p>その後胃カメラ検査になります。</p>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className="row box">
              <div className="col-sm-4">
                <img
                  className="img-with-text img-responsive center-block img-thumbnail"
                  src={InterviewImg}
                />
              </div>
              <div className="col-sm-8">
                <div>
                  <h3>Step08</h3>
                </div>
                <p>
                  検査が終わった後は、再度診察。超音波検査、胃カメラの結果をご説明いたします。説明が終わったら、お着替えいただき、ご帰宅いただきます。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Flow = () => (
  <Layout>
    <FlowContent />
  </Layout>
);

export default Flow;
